import React, { useState } from 'react';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import './App.css'; // Ensure your styles are defined here
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';


function App() {
  const [companyName, setCompanyName] = useState('');
  const documents = [
    { filename: "Acceptable Use Policy", url: "https://storage.googleapis.com/document-storage-advcs/Acceptable%20Use%20Policy.docx" },
    { filename: "Backup Policy", url: "https://storage.googleapis.com/document-storage-advcs/Backup%20Policy.docx" },
    { filename: "Data Retention Policy", url: "https://storage.googleapis.com/document-storage-advcs/Data%20Retention%20Policy.docx" },
    { filename: "Disaster Recovery Policy", url: "https://storage.googleapis.com/document-storage-advcs/Disaster%20Recovery%20Policy.docx" },
    { filename: "Employee Background Check Policy", url: "https://storage.googleapis.com/document-storage-advcs/Employee%20Background%20Check%20Policy.docx" },
    { filename: "Employee Credentials Stolen Playbook", url: "https://storage.googleapis.com/document-storage-advcs/Employee%20Credentials%20Stolen%20Playbook.docx" },
    { filename: "Employee Handbook", url: "https://storage.googleapis.com/document-storage-advcs/Employee%20Handbook.docx" },
    { filename: "Employee Offboarding Procedure", url: "https://storage.googleapis.com/document-storage-advcs/Employee%20Offboarding%20Procedure.docx" },
    { filename: "Incident Response Policy", url: "https://storage.googleapis.com/document-storage-advcs/Incident%20Response%20Policy.docx" },
    { filename: "Incident Response Procedures", url: "https://storage.googleapis.com/document-storage-advcs/Incident%20Response%20Procedures.docx" },
    { filename: "Password Policy", url: "https://storage.googleapis.com/document-storage-advcs/Password%20Policy.docx" },
    { filename: "Payment or Wire Fraud Playbook", url: "https://storage.googleapis.com/document-storage-advcs/Payment%20or%20Wire%20Fraud%20Playbook.docx" },
    { filename: "Ransomware Outbreak Playbook", url: "https://storage.googleapis.com/document-storage-advcs/Ransomware%20Outbreak%20Playbook.docx" },
    { filename: "Risk Management Policy", url: "https://storage.googleapis.com/document-storage-advcs/Risk%20Management%20Policy.docx" },
    { filename: "Security Awareness and Training Policy", url: "https://storage.googleapis.com/document-storage-advcs/Security%20Awareness%20and%20Training%20Policy.docx" },
    { filename: "Technical Security Controls Policy", url: "https://storage.googleapis.com/document-storage-advcs/Technical%20Security%20Controls%20Policy.docx" },
    { filename: "Vendor Access Policy", url: "https://storage.googleapis.com/document-storage-advcs/Vendor%20Access%20Policy.docx" },
    { filename: "Vendor and Employee Verification Playbook", url: "https://storage.googleapis.com/document-storage-advcs/Vendor%20and%20Verification%20Playbook.docx" },
    { filename: "Work from Home Policy", url: "https://storage.googleapis.com/document-storage-advcs/Work%20from%20Home%20Policy.docx" },
  ];

  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }


  const downloadFile = (filename, url) => {
    loadFile(url, function (error, content) {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({ orgName: companyName });

      try {
        doc.render();
      } catch (error) {
        console.error(JSON.stringify(error, Object.getOwnPropertyNames(error)));
        throw error;
      }

      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(out, filename);
    });
  };

  return (
    <div className="App">
    <header className="App-header">
      <a href="https://adversis.io" target="_blank" rel="noopener noreferrer">
    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
</a>

      <input
        type="text"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        placeholder="Enter company name"
      />  
      <br></br>
      
      <AwesomeButton type="primary"   style={{
    '--button-default-height': '50px', // Adjust the height to match your design
    '--button-default-font-size': '16px', // Choose font size that matches the text in the image
    '--button-default-border-radius': '30px', // A larger radius for rounded corners similar to the shield shape
    '--button-horizontal-padding': '30px', // Add more padding if necessary
    '--button-raise-level': '5px', // Shadow effect to match the logo's depth
    '--button-hover-pressure': '1', // Less pressure effect on hover
    '--transform-speed': '.185s', // Transition speed for the button press effect
    '--button-primary-color': '#EF476F', // Primary color taken from the logo's gradient
    '--button-primary-color-dark': '#FF6392', // A darker version of the primary color
    '--button-primary-color-light': '#ffffff', // A lighter tint of the primary color
    '--button-primary-color-hover': '#FF6392', // Hover color that's a bit darker than the primary color
    '--button-primary-border': 'none', // No border for a modern look
    '--button-secondary-color': '#06D6A0', // Secondary color from the logo's gradient
    '--button-secondary-color-dark': '#15FFC2', // A darker version of the secondary color
    '--button-secondary-color-light': '#76FFEC', // A lighter tint of the secondary color
    '--button-secondary-color-hover': '#15FFC2', // Hover color for the secondary button
    '--button-secondary-border': 'none', // No border for the secondary button
    '--button-anchor-color': '#118AB2', // Anchor button color from the logo's gradient
    '--button-anchor-color-dark': '#1C9AD6', // A darker version of the anchor color
    '--button-anchor-color-light': '#8CD1E6', // A lighter tint of the anchor color
    '--button-anchor-color-hover': '#1C9AD6', // Hover color for the anchor button
    '--button-anchor-border': 'none', // No border for the anchor button
  }}
href="/guide.pdf" target="_blank" rel="noopener noreferrer">Policies and Procedures Guidance</AwesomeButton>
      {(
        <table>
          <thead>
            <tr>
              <th>Policy Name</th>
              <th>Company Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <tr key={index}>
                <td>{doc.filename}</td>
                <td>{companyName}</td>
                <td>
                  <AwesomeButton type="primary"  disabled={!companyName}  onPress={() => downloadFile(doc.filename, doc.url)} style={{
    '--button-default-height': '50px', // Adjust the height to match your design
    '--button-default-font-size': '16px', // Choose font size that matches the text in the image
    '--button-default-border-radius': '30px', // A larger radius for rounded corners similar to the shield shape
    '--button-horizontal-padding': '30px', // Add more padding if necessary
    '--button-raise-level': '5px', // Shadow effect to match the logo's depth
    '--button-hover-pressure': '1', // Less pressure effect on hover
    '--transform-speed': '.185s', // Transition speed for the button press effect
    '--button-primary-color': '#EF476F', // Primary color taken from the logo's gradient
    '--button-primary-color-dark': '#FF6392', // A darker version of the primary color
    '--button-primary-color-light': '#ffffff', // A lighter tint of the primary color
    '--button-primary-color-hover': '#FF6392', // Hover color that's a bit darker than the primary color
    '--button-primary-border': 'none', // No border for a modern look
    '--button-secondary-color': '#06D6A0', // Secondary color from the logo's gradient
    '--button-secondary-color-dark': '#15FFC2', // A darker version of the secondary color
    '--button-secondary-color-light': '#76FFEC', // A lighter tint of the secondary color
    '--button-secondary-color-hover': '#15FFC2', // Hover color for the secondary button
    '--button-secondary-border': 'none', // No border for the secondary button
    '--button-anchor-color': '#118AB2', // Anchor button color from the logo's gradient
    '--button-anchor-color-dark': '#1C9AD6', // A darker version of the anchor color
    '--button-anchor-color-light': '#8CD1E6', // A lighter tint of the anchor color
    '--button-anchor-color-hover': '#1C9AD6', // Hover color for the anchor button
    '--button-anchor-border': 'none', // No border for the anchor button
  }}>Download</AwesomeButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </header>
  </div>
);
}


export default App;
